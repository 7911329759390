@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@font-face {
  font-family: 'Maru';
  src: local('Maru'), url(./fonts/Maru-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Marimaru';
  src: local('Marimaru'), url(./fonts/Marimaru25-Regular.otf) format('opentype');
}
body {
  margin: 0;
  font-family: 'Atkinson Hyperlegible','Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
